export const GlobalVariable = Object.freeze({
    // LOCAL
    // BASE_USER_API_URL: 'http://localhost/kekeli_api/WEB/USER',
    // BASE_MEDIA_URL: 'http://localhost/kekeli_api/photokekeliuser/',
    // BASE_PRODUITS_MEDIA_URL: 'http://localhost/kekeli_api/produits/',
    // BASE_PUBS_MEDIA_URL: 'http://localhost/kekeli_api/publicites/',
    // BASE_QUEST_IMAGES_URL: 'http://localhost/KEKELI/',
    
    // DEV
    BASE_USER_API_URL: 'https://api.kekeliedu.net/DEV/WEB/USER',
    BASE_MEDIA_URL: 'https://api.kekeliedu.net/DEV/photokekeliuser/',
    BASE_PRODUITS_MEDIA_URL: 'https://api.kekeliedu.net/DEV/produits/',
    BASE_PUBS_MEDIA_URL: 'https://api.kekeliedu.net/DEV/publicites/',
    BASE_PARTNERS_MEDIA_URL: 'https://api.kekeliedu.net/DEV/partenaires/',
    BASE_QUEST_IMAGES_URL: 'https://dev.kekeliedu.net/',
    
    // PROD
    // BASE_USER_API_URL: 'https://api.kekeliedu.net/PROD/WEB/USER',
    // BASE_MEDIA_URL: 'https://api.kekeliedu.net/PROD/photokekeliuser/',
    // BASE_PRODUITS_MEDIA_URL: 'https://api.kekeliedu.net/PROD/produits/',
    // BASE_PUBS_MEDIA_URL: 'https://api.kekeliedu.net/PROD/publicites/',
    // BASE_PARTNERS_MEDIA_URL: 'https://api.kekeliedu.net/PROD/partenaires/',
    // BASE_QUEST_IMAGES_URL: 'https://kekeliedu.net/',
    
    APP_UNIK_ID: 'KEKELI_APP_S'

});